<template>
    <v-dialog :value="value" persistent width="800">
        <v-card color="fill">
            <v-toolbar color="main" class="white--text">
            <v-toolbar-title>Reset Suggested Orders</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="mt-3">
                <div :style="{ 'font-size': '20px' }">
                    <span><strong>WARNING:</strong></span>
                </div>
                <v-checkbox
                class="mt-3"
                dense 
                v-model="agreed"
                :label="warningText">
                </v-checkbox>
            </v-card-text>
            <v-footer>
            <v-row justify="center" class="mb-2">
                <v-btn
                color="gray" 
                class='mb-3 mr-5'
                dense 
                medium
                @click="closeModal()">
                Cancel
                </v-btn>
                <v-btn 
                color="warning" 
                class='mb-3 mr-5' 
                dense 
                medium 
                :disabled="!agreed"
                :loading="loading"
                @click="resetOrders()">
                RESET ORDER GRID
                </v-btn>
            </v-row>
            </v-footer>
        </v-card>
    </v-dialog>
</template>

<script>
import OrderGrid from '@/axios/order-grid'
import store from '@/store/index'

export default {
  props: {
    value: Boolean,
    ad_id: Number,
    store_id: String,
    userEmail: String,
  },
  data() {
    return {
        loading: false,
        warningText: "I understand that resetting the order grid changes all items back to their original suggested order and clears any user's entered changes.",
        agreed: false,
    }
  },
  methods: {
    async resetOrders() {
        this.loading = true
        try {
            const payload = {
            user_email: this.userEmail
            }
            const response = await OrderGrid.resetOrders(this.ad_id, this.store_id, payload)
            if (response.data) {
                store.dispatch('showSnackbar', {
                    status: 'success',
                    message: 'Suggested Orders Reset'
                })
            }
        } catch (error) {
            console.log(error)
            store.dispatch('showSnackbar', {
            status: 'error',
            message: 'Error: Reset Failed'
            })
        }
        finally{
            this.loading = false
            this.agreed = false
            this.$emit('update:value', false)
            this.$emit('refresh-grid', true)
        }
    },
    closeModal() {
        this.agreed = false
        this.$emit('update:value', false)
    }
  }
}
</script>
