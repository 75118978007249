var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", width: "800" } },
    [
      _c(
        "v-card",
        { attrs: { color: "fill" } },
        [
          _c(
            "v-toolbar",
            { staticClass: "white--text", attrs: { color: "main" } },
            [_c("v-toolbar-title", [_vm._v("Reset Suggested Orders")])],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "mt-3" },
            [
              _c("div", { style: { "font-size": "20px" } }, [
                _c("span", [_c("strong", [_vm._v("WARNING:")])]),
              ]),
              _c("v-checkbox", {
                staticClass: "mt-3",
                attrs: { dense: "", label: _vm.warningText },
                model: {
                  value: _vm.agreed,
                  callback: function ($$v) {
                    _vm.agreed = $$v
                  },
                  expression: "agreed",
                },
              }),
            ],
            1
          ),
          _c(
            "v-footer",
            [
              _c(
                "v-row",
                { staticClass: "mb-2", attrs: { justify: "center" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-3 mr-5",
                      attrs: { color: "gray", dense: "", medium: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeModal()
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-3 mr-5",
                      attrs: {
                        color: "warning",
                        dense: "",
                        medium: "",
                        disabled: !_vm.agreed,
                        loading: _vm.loading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.resetOrders()
                        },
                      },
                    },
                    [_vm._v(" RESET ORDER GRID ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }